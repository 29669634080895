import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Grid, Link } from '@mui/material';
import icon from "../assets/Images/icon.jpg"
import "../css/signup.css"
const Signup = () => {

    return (
        <div className='home-container'>
            <div className='app-header'>
                <div className='app-name'>Forestor Buddy</div>
                <img className='app-logo' src="https://valiancesolutions.com/wp-content/themes/blankslate/assets/images/logo.png"></img>
            </div>
            {/* {
                logout ? <Alert severity="success">Logout Successful</Alert> : null
            } */}
            <div className='app-signup'>
                Thank you for expressing interest in our product. <br/> Our sales team will be in touch with you shortly
            </div>
            {/* <Button onClick={handleLogout} variant="contained" color="error">Logout</Button> */}
        </div>
    );
}

export default Signup;
